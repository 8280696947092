export const PARTNER_ITEMS = [
  {
    company: 'amp.svg',
    dimensions: {
      width: 92,
      height: 40
    }
  },
  {
    company: 'anz.svg',
    dimensions: {
      width: 103,
      height: 32
    }
  },
  {
    company: 'bank-of-melbourne.svg',
    dimensions: {
      width: 92,
      height: 24
    }
  },
  {
    company: 'bank-sa.svg',
    dimensions: {
      width: 88,
      height: 40
    }
  },
  {
    company: 'common-wealth.svg',
    dimensions: {
      width: 87,
      height: 57
    }
  },
  {
    company: 'bank-west.svg',
    dimensions: {
      width: 111,
      height: 22
    }
  },
  {
    company: 'macquarie.svg',
    dimensions: {
      width: 64,
      height: 70
    }
  },
  {
    company: 'ing.svg',
    dimensions: {
      width: 64,
      height: 64
    }
  },
  {
    company: 'nab.svg',
    dimensions: {
      width: 94,
      height: 36
    }
  },
  {
    company: 'suncorp.svg',
    dimensions: {
      width: 107,
      height: 32
    }
  },
  {
    company: 'st-george.svg',
    dimensions: {
      width: 111,
      height: 31
    }
  },
  {
    company: 'group-894.svg',
    dimensions: {
      width: 79,
      height: 32
    }
  },
  {
    company: 'pepper-money.svg',
    dimensions: {
      width: 96,
      height: 44
    }
  },
  {
    company: 'my-state-bank.svg',
    dimensions: {
      width: 67,
      height: 48
    }
  },
  {
    company: 'firstmac.svg',
    dimensions: {
      width: 102,
      height: 35
    }
  },
  {
    company: 'me.svg',
    dimensions: {
      width: 56,
      height: 56
    }
  }
]

export const GREAT_DEAL_ITEMS = [
  {
    title: 'Tell us what<br /> you need',
    icon: `${APP_ASSET_BASEURL}edge/static/images/steps/tell-us.svg`,
    iconDimensions: {
      width: 60,
      height: 60
    }
  },
  {
    title: 'We’ll find a suitable<br /> home loan from our<br /> panel',
    icon: `${APP_ASSET_BASEURL}edge/static/images/steps/find-plan.svg`,
    iconDimensions: {
      width: 65,
      height: 60
    }
  },
  {
    title: 'We’ll help you<br /> through the approval<br /> process',
    icon: `${APP_ASSET_BASEURL}edge/static/images/steps/approval.svg`,
    iconDimensions: {
      width: 64,
      height: 64
    }
  },
  {
    title: 'Relax. You’ve now<br /> got a home loan<br /> at a great rate',
    icon: `${APP_ASSET_BASEURL}edge/static/images/steps/relax.svg`,
    iconDimensions: {
      width: 64,
      height: 64
    }
  }
]

export const REPAYMENTS = [
  {
    title: 'Compare 40+ lenders',
    description: 'Our lender panel is one of the biggest in Australia. We compare all the big banks you already know and some specialist lenders other brokers don’t offer. Whatever you need, we’re confident there’s a home loan that suits you.'
  },
  {
    title: 'Working for you',
    description: 'We\'re mortgage brokers, and we’re not owned by a big bank or a home loan provider. That means we\'ve only got your best interests in mind. Our mission is to find you the best loan, not the mortgage that makes us the most money.'
  },
  {
    title: 'Expert support',
    description: 'Our specialists do the hard work for you. They\'ll speak to the lenders, help you out with the paperwork and be by your side until your loan is finalised. Traditional lenders can be slow and painful - everything we\'re not.'
  },
  {
    title: 'Seriously easy',
    description: 'You can find a range of loan options in a matter of minutes - all without leaving your sofa. No more time wasted speaking to banks who\'ll say no or realising that you don\'t meet a lender\'s criteria.'
  }
]

export const GUIDES_ITEMS = [
  {
    title: 'What does the RBA’s current cash rate mean for your home loan?',
    content: 'Each month the Reserve Bank of Australia issues an update that affects interest rates on home loans. Here\'s what you need to know.',
    imageUrl: `${APP_ASSET_BASEURL}edge/static/images/guides/home-loans/rba-cash-rate.png`,
    pageUrl: '/home-loans/rba-rate-update/',
    imageDimensions: {
      width: 291,
      height: 202
    }
  },
  {
    title: 'How to refinance your current home loan',
    content: 'Redraw facilities and offset accounts are two common features you’ll encounter in the mortgage hopping process. Both features can save you money, but only if you use them to their full potential.',
    imageUrl: `${APP_ASSET_BASEURL}edge/static/images/guides/home-loans/refinance.png`,
    pageUrl: '/home-loans/refinancing/',
    imageDimensions: {
      width: 291,
      height: 202
    }
  },
  {
    title: 'How to get faster pre-approval for your home loan',
    content: 'Found the home of your dreams, but don’t know how to move quickly? Here’s what you need to know about getting a faster home loan approval.',
    imageUrl: `${APP_ASSET_BASEURL}edge/static/images/guides/home-loans/pre-approval.png`,
    pageUrl: '/home-loans/fast-pre-approval/',
    imageDimensions: {
      width: 291,
      height: 202
    }
  }
]

export const FAQ_ITEMS = [
  {
    title: 'What is the best mortgage comparison website?',
    content: 'Home loan comparison website should make it easy for you to get estimates from a wide range of home loan lenders. Using a comparison website is all about convenience, so you want to make sure the platform you’re using makes it easy to find the right home loan. <br><br> Our experts at Compare Club make comparing home loans as simple as entering a few details and letting us handle the rest. <a class="underline" href="/home-loans/quote/form/step1/">Get started today.</a>'
  },
  {
    title: 'What’s the difference between fixed and variable home loan interest rates?',
    content: 'Fixed-rate home loans mean that the interest rate remains the same for the entire loan. The advantage of a fixed-rate loan is that you can be 100% certain of what your interest repayments will be. Having a fixed-rate loan also means you’re protected if interest rates rise. <br><br> The interest rate on variable-rate home loans will change whenever the bank chooses by however much the bank chooses to change the rates to. <br><br> There are pros and cons to fixed and variable rate mortgages. There are some things you can consider before deciding which financial product is right for you, including: <br><ul class="my-4 pl-12 list-disc"><li>Are additional repayments important to you? Some fixed rate lenders put limits on extra repayments, whereas variable loans have no limit.</li><li>Are you planning to close your mortgage in the near future?</li><li>Do you prefer the certainty of a monthly set repayment amount? Fixed home loans can give you the certainty you need.</li></ul><br> Our experts are here to help you navigate through fixed term and variable rate home loans. <a class="underline" href="/home-loans/quote/form/step1/">Compare today</a> and find the loan that works for you.'
  },
  {
    title: 'What is lenders mortgage insurance?',
    content: 'Lenders mortgage insurance (LMI) is designed to protect the lender when the borrower has a low deposit and the lender views the loan as a higher risk in case of default. <br><br> LMI is typically a one-off payment you can expect to be charged if you borrow more than 80% of the home’s value.'
  },
  {
    title: 'What is LVR?',
    content: 'As you compare home loans, you may come across the term LVR. LVR stands for loan-to-value ratio and represents the amount you borrow as a percentage of the property value. <br><br> For example, if you were borrowing $350,000 to pay for a $500,000 home, then your LVR would be calculated as $350,000 ÷ $500,000 x 100 = 70% LVR.'
  },
  {
    title: 'What are principal and interest loan repayments?',
    content: 'Principal and interest loans will have monthly repayments covering a small amount of the main amount you borrowed and the interest you owe the lender. <br><br> Some home loans, such as investment loans, may charge interest-only repayments for a certain period before principal and interest payments begin. <br><br> On top of regular home loan repayments, some loans give you the option to make extra repayments. Making extra repayments with any spare money you have helps reduce the total amount you owe, which also lowers your next interest payment. <br><br> Consistently making extra repayments can significantly reduce the cost of your loan.'
  },
  {
    title: 'How do I calculate my principal and interest home loan repayments?',
    content: 'You can access several free online home loan calculators that will give you an estimate of your principal and interest repayments. All you need to do is input some basic details like the principal amount you want to borrow and the interest rate. <br><br> Compare Club’s experts go above and beyond a calculator. Your specialist will let you know your monthly loan repayment for each option. <br><br> Rather than using a calculator to estimate your repayments on a theoretical home loan, Compare Club can give you real numbers for home loan products you can actually use. <a class="underline" href="/home-loans/quote/form/step1/">Get started today</a>.'
  },
  {
    title: 'What is an offset account?',
    content: 'An offset account is a standard bank account linked to your home loan. The money you deposit in the account is offset against what you still owe. <br><br> The more money you have in your offset account, the less total interest you will need to pay. For example, if you have a home loan of $500,000 linked to an offset account with $50,000 in it, you would only be charged interest on $450,000. <br><br> Home loans that offer offset accounts have various settings that change how they function and how much you can save. When you compare home loans, it’s a good idea to investigate the details of a home loan with an offset account, so you know if it will be worthwhile for you.'
  }
]
