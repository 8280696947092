import { CONFIG } from './config'

class HomeLoanService {
  constructor (env = 'develop') {
    this.baseUrl = CONFIG.baseUrl(env, 'alternativemedia')
  }

  getVariableRate () {
    return $fetch('/frontend-config/get-config/HOMELOAN?fieldName=variableRate', {
      method: 'GET',
      baseURL: `${this.baseUrl}`
    })
  }

  comparisonRateItem () {
    return $fetch('/frontend-config/get-config/HOMELOAN?fieldName=comparisonRate', {
      method: 'GET',
      baseURL: `${this.baseUrl}`
    })
  }

  cashRateDateItem () {
    return $fetch('/frontend-config/get-config/HOMELOAN?fieldName=cashRateDate', {
      method: 'GET',
      baseURL: `${this.baseUrl}`
    })
  }

  getInterestRateImage () {
    return $fetch('/frontend-config/get-config/HOMELOAN?fieldName=interestRateImage', {
      method: 'GET',
      baseURL: `${this.baseUrl}`
    })
  }
}

export default HomeLoanService
